import { Component } from 'react'
import {
  useState,
  useEffect,
} from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import Router, {
  useRouter,
} from 'next/router'
import {
  createUseStyles,
  withStyles,
} from 'react-jss'
import {
  connect,
} from 'react-redux'
import classNames from 'classnames'
import merge from 'deepmerge'
import { Fade } from 'react-slideshow-image'
import Slider from 'react-slick'
import {
  Image,
  BackgroundImage,
} from 'react-image-and-background-image-fade'
import Collapse from '@material-ui/core/Collapse'
import MuiButton from '@material-ui/core/Button'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  getShops,
} from '../redux/api/actions'
import getViewportForCoordinates from '../utils/getViewportForCoordinates'
import setCookieAndLoadProfile from '../utils/setCookieAndLoadProfile'
import redirectIfLoggedIn from '../utils/redirectIfLoggedIn'
import Colors from '../utils/Colors'
import {
  BREAKPOINTS,
} from '../utils/theme'
import {
  DEFAULT_SHOP_IMAGE,
} from '../utils/Constants'
import getUrlForPhoto from '../utils/getUrlForPhoto'
import Logo from '../components/Logo'
import Button from '../components/Button'
import Ribbon from '../components/Ribbon'
import Footer from '../components/Footer'
import LocationPinIcon from '../components/icons/LocationPinIcon'
import DownArrowIcon from '../components/icons/DownArrowIcon'
import NavMenuIcon from '../components/icons/NavMenuIcon'

const ExploreMap = dynamic(() => import('../components/home/ExploreMap'))

import 'react-slideshow-image/dist/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const HEADER_TRANSITION_HEIGHT = 100
const HERO_HEIGHT_LARGE = 800
const HERO_HEIGHT_MEDIUM = 458
const HERO_IMAGE_DURATION = 4000
const HERO_IMAGE_TRANSITION_DURATION = 800

const ITEM_IMAGE_HEIGHT = 260

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
const mapEasing = t => 1 - (1 - t) * (1 - t)

const HERO_IMAGES = [
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/shop.jpg',
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/kishi.jpg',
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/wada.jpg',
]

const DYNAMIC_COPY = {
  default: {
    appInfo: {
      title: `月額12,980円で1日1回\n定食でもお酒でも何でも1,000円OFFの\nSASAYAのサブスク！`,
      text1: `SASAYAグループ対象店舗共通で使えるので、和食・焼肉・中華・蕎麦・アジア料理など、毎日バラエティ豊かなお食事やお酒をお楽しみいただけます！最大11：00～翌4：00(L.O3：00)までご利用頂けます！\n(※営業時間は店舗により異なります)`,
      text2: `飲み会でも、一人呑みでも、定食でも、使い方はいろいろ！`,
    },
    features: {
      text1: `定額制で、1日1回税込1,000円以下の定食が食べられます。（一部店舗は税込700円まで）`,
      text2: `お店に行ってお好きなメニューを普通に注文。お会計時にご利用商品を追加してスタッフに提示。`,
      text3: `SASAYAグループのお店で使えるので、和食、中華、焼肉、ヘルシー料理などさまざまなジャンルから選び放題。`,
    },
    recommend: {
      subtitle: `お楽しみいただけるメニュー事例`,
    },
    howToUse: {
      section1: {
        title: `1.アプリDL`,
        text: `ストアよりアプリをダウンロードしアカウント作成`,
      },
      section2: {
        title: `2.プラン購入`,
        text: `アプリ内マイページよりサブスクのプランを購入`,
      },
      section3: {
        title: `3.食後にアプリを提示`,
        text: `お会計の際にスタッフにサブスクご利用を伝え、アプリ内のボタンをタップ！`,
      },
    },
    plans: [{
      titleTop: false,
      title: '3食トライアル',
      price: 290,
      priceWithTax: 313,
      strikethroughPrice: '¥590/1食',
      ribbon: '1週間お試し',
      perType: 'ticket',
      description: 'トライアル終了後\n12食プランに自動更新',
    }, {
      titleTop: false,
      title: '12食プラン',
      price: 590,
      priceWithTax: 637,
      perType: 'ticket',
      description: '30日間で最大12食ご利用可能\n月々¥7,080（税込¥7,646）',
    }, {
      titleTop: false,
      title: '平日毎日1食プラン',
      price: 490,
      priceWithTax: 529,
      perType: 'day',
      description: '土日を除く昼or夜1回ご利用可能\n月々¥9,800（税込¥10,584）',
    }],
  },
}

const renderDynamicCopyString = dynamicCopyString => dynamicCopyString.split('\n').map((line, index) => (
  <div key={index}>
    {line}
  </div>
))

const MEALS = [{
  id: 1,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-1.jpeg',
  name: 'かしわ焼肉セット',
  shop: '藤本食堂',
  neighborhood: '浪速区',
}, {
  id: 2,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-2.jpeg',
  name: 'もも肉天然塩焼き',
  shop: '備長炭やきとり 笹屋道頓堀店',
  neighborhood: '中央区',
}, {
  id: 3,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-3.jpeg',
  name: '鰻串6本',
  shop: 'うなぎのじょん',
  neighborhood: '中央区',
}, {
  id: 4,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-4.jpeg',
  name: '鉄鍋餃子',
  shop: '鉄鍋餃子 林田',
  neighborhood: '中央区',
}, {
  id: 5,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-5.jpeg',
  name: '渡蟹鉄也',
  shop: 'しじみ炊き肉 くにき難波店',
  neighborhood: '浪速区',
}, {
  id: 6,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-6.jpeg',
  name: 'フライドチキン定食',
  shop: 'ナカダサロン',
  neighborhood: '中央区',
}, {
  id: 7,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-7.jpeg',
  name: '焼き肉丼',
  shop: '七輪焼肉SASAYA',
  neighborhood: '中央区',
}, {
  id: 8,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-8.jpeg',
  name: '担々麺定食',
  shop: '餃子酒場あめみや',
  neighborhood: '浪速区',
}, {
  id: 9,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/sasaya-home-menus-5-2022/home-menu-9.jpeg',
  name: '麻婆豆腐定食',
  shop: '武漢熱干面太志',
  neighborhood: '浪速区',
}]

const DEFAULT_MAP_LOCATION = {
  latitude: 34.68830116968518,
  longitude: 135.49782005190315,
  zoom: 12,
}

const MAP_LOCATIONS = [{
  value: 1,
  label: '中央区',
  latitude: 34.6782950,
  longitude: 135.5144780,
  zoom: 13,
}, {
  value: 2,
  label: '北区',
  latitude: 34.7049560,
  longitude: 135.5024710,
  zoom: 13,
}/*, {
  value: 3,
  label: '都島区',
  latitude: 34.7118810,
  longitude: 135.5271860,
  zoom: 13,
}, {
  value: 4,
  label: '浪速区',
  latitude: 34.6594038,
  longitude: 135.4869885,
  zoom: 13,
}, {
  value: 5,
  label: '西区',
  latitude: 34.6798619,
  longitude: 135.4713009,
  zoom: 13,
}, {
  value: 6,
  label: '福島区',
  latitude: 34.6937067,
  longitude: 135.4679024,
  zoom: 13,
}*/]

const FAQ_QUESTIONS = [{
  question: 'Q1、プランの購入方法を教えてください。',
  answer: 'A1、マイページ→アカウント→プランよりクレジットカードをご登録後にてご購入が可能です。',
}, {
  question: 'Q2、お支払い方法は何がありますか？',
  answer: 'A2、クレジットカードでのお支払いが可能です。',
}, {
  question: 'Q3、プランの解約はどのようにできますか？',
  answer: 'A3、次回サイクルのご解約はアプリ内のマイページ→アカウント→プランより更新日時まで可能です。\n※ご解約なく更新日時を過ぎますと自動更新いたしますのでご了承くださいませ。',
}, {
  question: 'Q4、同じアカウントを何人でも使えますか？',
  answer: 'A4、ご利用はご購入者のみとなります。不正利用発見の場合には、ご利用人数分のご請求をさせていただくことがございます。',
}, {
  question: 'Q5、1,000円を越える場合の支払いはどのように行いますか？',
  answer: (
    <div>
      A5、追加でのお支払いに関しましては、店舗にて直接お支払いくださいませ。<br />
      ※次の4店舗では、700円割引として利用頂けます(八百屋とごはんおおくら、うらや本店、うらや西本町店、うらや福島店)
    </div>
  ),
}, {
  question: 'Q6、クレジットカードの支払いに失敗した場合はどのようにすれば良いですか？（突然利用できなくなった場合こちらの可能性が高いです。）',
  answer: (
    <div>
      A6、更新時に何らかの理由でクレジットカードが利用できない状態になっている場合、決済が行われず自動解約となります。<br />
      なお、決済が行われなかった理由については、ご利用のカード会社へお問い合わせください。<br />
      また、募集人数上限に達している場合など、再購入いただくことができない場合がございますので、あらかじめご了承ください。<br />
      再開をご希望の方は以下のフォームにお問い合わせください。
      <a
        href="https://forms.gle/txTvNfA7vETgK5Nh6"
        style={{
          color: 'blue',
        }}>
        https://forms.gle/txTvNfA7vETgK5Nh6
      </a>
    </div>
  ),
}, {
  question: 'Q7、お店の営業時間はどのように確認しますか？',
  answer: 'A7、各店舗のホームページもしくはお電話にてご確認くださいませ。',
}, {
  question: 'Q8、登録クレジットカードの変更方法は？',
  answer: 'A8、下記の手順にてクレジットカード情報の変更が可能です。マイページ→アカウント→お支払い方法の右上「＋」ボタンより新規のカードをご登録ください。メインでご利用される（デフォルト）カードの変更は編集→デフォルトに設定する、より変更可能です。',
}, {
  question: 'Q9、一度に2食以上利用することは可能ですか？',
  answer: 'A9、ご利用は1日（0:00~23:59）に1回のみのご利用となりますので、一度に2食以上のご利用はできませんのでご了承くださいませ。',
}, {
  question: 'Q10、アプリの不具合についてはどこに連絡したら良いですか？',
  answer: (
    <div>
      A10、下記のフォームにご入力いただけますと、運営会社よりご連絡させていただきます。
      <a
        href="https://forms.gle/txTvNfA7vETgK5Nh6"
        style={{
          color: 'blue',
        }}>
        https://forms.gle/txTvNfA7vETgK5Nh6
      </a>
    </div>
    ),
}]

const useAppDownloadBarStyles = createUseStyles(theme => ({
  appDownloadBar: {
    padding: theme.spacing.unit,
    backgroundColor: Colors.primaryPink,
  },
  appDownloadBarContent: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  appDownloadBarLogo: {
    height: 84,
    width: 84,
    [theme.breakpoints.down('sm')]: {
      height: 70,
      width: 70,
    },
  },
  appDownloadBarText: {
    fontWeight: 'bold',
    color: '#ffffff',
    marginLeft: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing.unit,
    }
  },
  appDownloadBarCaret: {
    flex: 1,
    height: 32,
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/carets.png)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  appDownloadBarAppLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  ctaAppstore: {
    width: 189,
    height: 56,
    backgroundImage: 'url(https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/appstore.svg)',
  },
  ctaPlaystore: {
    width: 189,
    height: 56,
    backgroundImage: 'url(https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/playstore.svg)',
    marginLeft: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing.unit,
    },
  },
}))

const AppDownloadBar = () => {
  const classes = useAppDownloadBarStyles()

  return (
    <div className={classes.appDownloadBar}>
      <div className={classes.appDownloadBarContent}>
        <Logo className={classes.appDownloadBarLogo} />
        <div className={classes.appDownloadBarText}>
          アプリの<br />
          ダウンロードは<br />
          こちらから
        </div>
        <div className={classes.appDownloadBarCaret} />
        <div className={classes.appDownloadBarAppLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/sasaya/id1596842641">
            <div className={classes.ctaAppstore} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.rymco.sasaya">
            <div className={classes.ctaPlaystore} />
          </a>
        </div>
      </div>
    </div>
  )
}

const useSectionStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    backgroundColor: Colors.white,
    textAlign: 'center',
  },
  containerPink: {
    backgroundColor: Colors.primaryPink,

    '&:before': {
      zIndex: 2,
      position: 'absolute',
      top: -14,
      left: 0,
      right: 0,
      height: 14,
      background: 'linear-gradient(45deg,#CC041B 50%,transparent 52%),linear-gradient(315deg,#CC041B 50%,transparent 52%)',
      backgroundSize: '30px 30px',
      content: '""',
    },

    '&:after': {
      zIndex: 2,
      position: 'absolute',
      bottom: -14,
      left: 0,
      right: 0,
      height: 14,
      background: 'linear-gradient(45deg,#CC041B 50%,transparent 52%),linear-gradient(315deg,#CC041B 50%,transparent 52%)',
      backgroundSize: '30px 30px',
      content: '""',
      transform: 'rotate(180deg)',
    },
  },
  contentWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing.unit * 8,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit * 4,
      paddingBottom: theme.spacing.unit * 4,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  contentWrapperNoHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 'none',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '27px',
    textAlign: 'center',
    letterSpacing: '0.25em',
    color: Colors.softText,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '18px',
    },
  },
  titlePink: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  subtitle: {
    fontSize: 32,
    fontWeight: '500',
    lineHeight: '48px',
    letterSpacing: '0.1em',
    color: Colors.primaryPink,
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '28px',
    },
  },
  subtitlePink: {
    color: Colors.white,
  },
  content: {
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit * 2,
    },
  },
}))

const Section = ({ title, subtitle, color="white", children, noHorizontalPadding=false }) => {
  const classes = useSectionStyles()

  return (
    <div className={classNames(classes.container, {
      [classes.containerPink]: color === 'pink',
    })}>
      <div className={classNames(classes.contentWrapper, {
        [classes.contentWrapperNoHorizontalPadding]: noHorizontalPadding,
      })}>
        {title && (
          <div className={classNames(classes.title, {
            [classes.titlePink]: color === 'pink',
          })}>
            {title}
          </div>
        )}
        <div className={classNames(classes.subtitle, {
          [classes.subtitlePink]: color === 'pink',
        })}>
          {subtitle}
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

const SECTION_ITEM_SIZE = 316
const SECTION_ITEM_SIZE_SM = 200

const useSectionItemStyles = createUseStyles(theme => ({
  container: {
    width: SECTION_ITEM_SIZE,
    height: SECTION_ITEM_SIZE,
    borderRadius: SECTION_ITEM_SIZE / 2,
    paddingTop: SECTION_ITEM_SIZE / 4,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    backgroundColor: Colors.primaryPink,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: SECTION_ITEM_SIZE_SM,
      height: SECTION_ITEM_SIZE_SM,
      borderRadius: SECTION_ITEM_SIZE_SM / 2,
      paddingTop: SECTION_ITEM_SIZE_SM / 4,
    }
  },
  item: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '30px',
    textAlign: 'center',
    color: Colors.white,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '18px',
    }
  },
  text: {
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'center',
    color: Colors.white,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '18px',
    }
  },
}))

const SectionItem = ({ image, title, text, className, imageClassName }) => {
  const classes = useSectionItemStyles()

  return (
    <div className={classNames(classes.container, className)}>
      {title && (
        <div className={classes.title}>
          {title}
        </div>
      )}
      {text && (
        <div className={classes.text}>
          {text}
        </div>
      )}
    </div>
  )
}

const usePlanCardStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    border: '3px solid #000000',
    borderRadius: 5,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    }
  },
  containerFat: {
    width: 499,
    height: 121,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ribbon: {
    position: 'absolute',
    height: 137,
    width: 135,
    top: -37,
    left: -37,
  },
  content: {
    zIndex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  contentFat: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#232323',
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  },
  titleFat: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  titleTop: {
    position: 'absolute',
    top: theme.spacing.unit,
    right: 0,
    left: 0,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#CC041B',
  },
  strikethroughPrice: {
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    textDecoration: 'line-through',
    color: '#CC041B',
    marginBottom: -8,
  },
  price: {
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#CC041B',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 24,
  },
  priceMonth: {
    marginLeft: 0,
    marginRight: theme.spacing.unit * -1,
  },
  perType: {
    fontSize: 18,
  },
  tax: {
    fontSize: 10,
    lineHeight: '24px',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  description: {
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#666666',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  descriptionFat: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  descriptionLine: {
    margin: 0,
  },
}))

const PlanCard = ({ plan, fat, className, ...rest }) => {
  const classes = usePlanCardStyles()

  let titleContents = [(
    <div key="title-top">
      {plan.titleTop &&
        <div className={classes.titleTop}>
          {plan.titleTop}
        </div>
      }
      <div
        key="title"
        className={classNames(classes.title, {
          [classes.titleFat]: fat,
        })}>
        {plan.title}
      </div>
    </div>
  )]

  if (plan.strikethroughPrice) {
    titleContents.push(
      <div
        key="strikethrough"
        className={classes.strikethroughPrice}>
        {plan.strikethroughPrice}
      </div>
    )
  }

  titleContents.push(
    <div
      key="price"
      className={classNames(classes.price, {
      [classes.priceMonth]: plan.perType,
    })}>
      ¥{plan.price}
      {plan.perType === 'ticket' && (
        <span className={classes.perType}>
          /1食
        </span>
      )}
      {plan.perType === 'day' && (
        <span className={classes.perType}>
          /1日
        </span>
      )}
      {plan.perType === 'month' && (
        <span className={classes.perType}>
          /1月
        </span>
      )}
      <span className={classes.tax}>
        （税込¥{plan.priceWithTax}）
      </span>
    </div>
  )

  if (fat) {
    titleContents = (
      <div>
        {titleContents}
      </div>
    )
  }

  return (
    <div
      {...rest}
      className={classNames([classes.container, className], {
        [classes.containerFat]: fat,
      })}>
      {plan.ribbon && (
        <Ribbon
          height={137}
          width={135}
          ribbon={plan.ribbon}
          className={classes.ribbon} />
      )}
      <div className={classNames(classes.content, {
        [classes.contentFat]: fat,
      })}>
        {titleContents}
        {plan.description && (
          <div className={classNames(classes.description, {
            [classes.descriptionFat]: fat,
          })}>
            {plan.description.split('\n').map((line, index) => (
              <p
                key={index}
                className={classes.descriptionLine}>
                {line}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const LazyLoader = () => (<div />)

const styles = theme => ({
  container: {
  },
  hero: {
    position: 'relative',
  },
  heroImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    height: HERO_HEIGHT_LARGE,
    [theme.breakpoints.down('sm')]: {
      height: HERO_HEIGHT_MEDIUM,
    }
  },
  heroGradient: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(90.98deg, rgba(0, 0, 0, 0.25) 14.17%, rgba(105, 105, 105, 0.15) 53.92%)',
    backgroundSize: 'cover',
    backgroundPosition: '80% 0%',
  },
  heroContentWrapper: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroContent: {
    width: '100%',
    maxWidth: 1200,
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  heroTitle: {
    color: Colors.white,
    fontSize: 56,
    marginTop: theme.spacing.unit * 20,
    fontWeight: 'bold',
    lineHeight: '72px',
    textShadow: '0px 0px 30px rgb(0 0 0 / 40%)',
    letterSpacing: '0.05em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
      lineHeight: '48px',
      marginTop: theme.spacing.unit * 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '32px',
      marginTop: theme.spacing.unit * 10,
    },
  },
  heroText: {
    color: Colors.white,
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    textShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginTop: theme.spacing.unit,
      marginLeft: 0,
    },
  },
  heroTextRed: {
    color: Colors.primaryPink,
  },
  awardImage: {
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/award-emblem.webp)',
    backgroundSize: 'cover',
    width: 200,
    height: 200,
    margin: 50,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing.unit * 2,
      width: 120,
      height: 120,
    },
  },
  appInfo: {
    background: 'linear-gradient(360deg, rgba(196, 196, 196, 0.16) -23.24%, rgba(196, 196, 196, 0) 44.4%)',
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 8,
    paddingRight: theme.spacing.unit * 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 0,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  appInfoSection: {
    maxWidth: 450,
    minWidth: 450,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      // width: 'auto',
      maxWidth: 'none',
      minWidth: 'auto',
      minHeight: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    }
  },
  appInfoTitle: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '40px',
    color: '#CC041B',
    paddingBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
      marginTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 2,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: theme.spacing.unit * 4,
    },
  },
  appInfoText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '30px',
    color: '#232323',
    paddingBottom: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  fakePhone: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/cliped.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  sectionItems: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1200,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionItemsScreenshot: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: -70,
    },
  },
  sectionItem: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing.unit * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  sectionItemScreenshot: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: 500,
    marginBottom: -70,
    [theme.breakpoints.down('md')]: {
      width: 240,
      height: 400,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 4,
      marginBottom: 0,
    },
  },
  sectionItemTitle: {
    backgroundColor: Colors.primaryPink,
    color: '#ffffff',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  sectionItemSubtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.primaryCharcoal,
    marginTop: theme.spacing.unit * 2,
    minHeight: 40, // make sure all items are the same height even though they have different numbers of lines of text
    [theme.breakpoints.down('sm')]: {
      minHeight: 'none',
    }
  },
  sectionItemImage: {
    flex: 1,
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    marginTop: theme.spacing.unit * 2,
  },
  map: {
    position: 'relative',
    height: 600,
    backgroundColor: '#F2F2F2',
    border: '3px solid #000000',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      height: 400,
    }
  },
  mapNeighborhoodSelector: {
    position: 'absolute',
    top: 30,
    right: 30,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 10,
    },
  },
  citySelectorCta: {
    fontFamily: 'Hiragino Sans',
    minWidth: 175,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderRadius: 36,
    [theme.breakpoints.down('sm')]: {
      minWidth: 125,
      paddingTop: theme.spacing.unit / 2,
      paddingBottom: theme.spacing.unit / 2,
    },
  },
  locationPinIcon: {
    height: 22,
    width: 16,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      height: 18,
      width: 12,
    },
  },
  cityName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  downArrowIcon: {
    height: 7,
    width: 15,
    marginLeft: 'auto',
  },
  cityDropdown: {
    backgroundColor: '#ffffff',
    maxHeight: 164,
    width: 160,
    borderRadius: 3,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    overflow: 'auto',
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      width: 125,
    },
  },
  cityListItem: {
    '&:hover': {
      backgroundColor: 'rgba(255, 96, 105, 0.15)',
    },
  },
  cityListItemText: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  mealImages: {
    marginBottom: theme.spacing.unit * -4,
  },
  mealImageContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: theme.spacing.unit * 2,
    outline: 'none',
  },
  mealImage: {
    borderRadius: 3,
  },
  mealShadow: {
    background: 'linear-gradient(89.88deg, rgba(0, 0, 0, 0.3) 3.92%, rgba(255, 255, 255, 0) 73.7%)',
    position: 'absolute',
    top: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    bottom: theme.spacing.unit * 2,
    borderRadius: 3,
  },
  mealNeighborhood: {
    position: 'absolute',
    top: 30,
    left: 40,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      left: 30,
    },
  },
  mealLocationPin: {
    height: 17,
    width: 13,
  },
  mealNeighborhoodText: {
    color: '#ffffff',
    fontSize: 18,
    marginLeft: theme.spacing.unit,
    marginTop: -4,
  },
  mealInfo: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      left: 30,
      right: 30,
    },
  },
  mealTitle: {
    fontSize: 18,
    lineHeight: '32px',
    letterSpacing: '0.05em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    borderBottom: '1px solid #FFFFFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  mealSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  plan: {
    backgroundColor: '#ffffff',
    border: '3px solid #000000',
    borderRadius: 5,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#666666',
  },
  faqItem: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  faqTitle: {
    fontSize: 14,
    fontWeight: '600',
  },
  faqBody: {
    marginTop: theme.spacing.unit,
    textAlign: 'left',
  },
  scrollSecondaryText: {
    marginTop: 0,
    fontSize: '12px',
    fontWeight: '400',
  },
})

class LandingPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mapLocation: {
        ...MAP_LOCATIONS[0],
        ...DEFAULT_MAP_LOCATION,
      },
      mapLocationSelectorShowing: false,
      viewport: {
        ...DEFAULT_MAP_LOCATION,
        transitionDuration: 2000,
        transitionEasing: mapEasing,
      },
      popup: null,
      expandedPanelIndex: null,
    }
  }

  onViewportChange = viewport => {
    this.setState({
      viewport,
    })
  }

  onClickMapLocationCta = () => {
    this.setState({
      mapLocationSelectorShowing: true,
    })
  }

  onSelectMapLocation = mapLocation => {
    this.setState({
      mapLocation,
      viewport: {
        zoom: mapLocation.zoom,
        latitude: mapLocation.latitude,
        longitude: mapLocation.longitude,
      },
      mapLocationSelectorShowing: false,
    })
  }

  onClickMap = event => {
    const feature = event.features.find(feature => feature.layer?.id === 'shops-layer')

    if (feature) {
      const shop = this.props.shops.data.find(shop => shop.id === feature.id)

      const viewport = getViewportForCoordinates({
        coordinates: [{
          latitude: shop.location.lat,
          longitude: shop.location.lon,
        }],
      })

      let bannerImage = DEFAULT_SHOP_IMAGE

      if (shop.media && shop.media.bannerImage) {
        bannerImage = getUrlForPhoto({ uuid: shop.media.bannerImage }, {
          size: 'medium',
        })
      }

      this.setState({
        viewport: {
          ...this.state.viewport,
          ...viewport,
          transitionDuration: 700,
          transitionEasing: mapEasing,
        },
        popup: {
          shop,
          image: bannerImage,
        }
      })
    }
  }

  onChangeExpandedPanel = index => {
    if (index === this.state.expandedPanelIndex) {
      this.setState({
        expandedPanelIndex: null,
      })
    } else {
      this.setState({
        expandedPanelIndex: index,
      })
    }
  }

  onClickSignup = () => {
    window.location = 'http://onelink.to/sasaya'
  }


  componentWillUnmount() {
    if (process.browser) {
      window.document.removeEventListener('scroll', this.listenScrollEvent)
    }
  }

  render() {
    let copy

    if (this.props.query.descriptor && DYNAMIC_COPY[this.props.query.descriptor]) {
      copy = merge(DYNAMIC_COPY.default, DYNAMIC_COPY[this.props.query.descriptor], { arrayMerge: overwriteMerge })
    } else {
      copy = DYNAMIC_COPY.default
    }

    return (
      <div className={this.props.classes.container}>
        <Head>
          <link href="https://fonts.googleapis.com/css2?family=Comfortaa&family=Indie+Flower&display=swap" rel="stylesheet" />
          <script dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "81ysr2vtlx");
            `
          }} />
        </Head>
        <div className={this.props.classes.hero}>
          <Fade
            canSwipe={false}
            pauseOnHover={false}
            arrows={false}
            easing="ease"
            duration={HERO_IMAGE_DURATION}
            transitionDuration={HERO_IMAGE_TRANSITION_DURATION}>
            {HERO_IMAGES.map(image => (
              <div
                key={image}
                className={this.props.classes.heroImage}
                style={{'backgroundImage': `url(${image})`}} />
            ))}
          </Fade>
          <div className={this.props.classes.heroContentWrapper}>
            <div className={this.props.classes.heroContent}>
              <div className={this.props.classes.heroTitle}>
                月額12,980円で1日1回
              </div>
              <div className={this.props.classes.heroText}>
                定食でもお酒でも何でも1,000円OFF！
                <br />
                SASAYAのサブスク！
              </div>
              <div className={this.props.classes.learnMoreCtas}>
            </div>
            </div>
          </div>
          <div className={this.props.classes.heroGradient} />
        </div>
        <AppDownloadBar />
        <div className={this.props.classes.appInfo}>
          <div className={this.props.classes.appInfoSection}>
            <div className={this.props.classes.appInfoTitle}>
              {renderDynamicCopyString(copy.appInfo.title)}
            </div>
            <div className={this.props.classes.appInfoText}>
              {renderDynamicCopyString(copy.appInfo.text1)}
            </div>
            <div className={this.props.classes.appInfoText}>
              {renderDynamicCopyString(copy.appInfo.text2)}
            </div>
          </div>
        </div>
        <Section
          subtitle="SASAYAサブスク3つの特徴">
          <div className={classNames([this.props.classes.sectionItems, this.props.classes.sectionItemsScreenshot])}>
            <div className={classNames([this.props.classes.sectionItem, this.props.classes.sectionItemScreenshot])}>
              <div className={this.props.classes.sectionItemTitle}>
                夜でも昼でも選んで使える
              </div>
              <div className={this.props.classes.sectionItemSubtitle}>
                定食でもお酒でも何でも1,000円OFFのSASAYAのサブスク！
              </div>
              <div
                className={this.props.classes.sectionItemImage}
                style={{
                  backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/Features1-new.png)',
                }} />
            </div>
            <div className={classNames([this.props.classes.sectionItem, this.props.classes.sectionItemScreenshot])}>
              <div className={this.props.classes.sectionItemTitle}>
                大阪を中心に60店舗！
              </div>
              <div className={this.props.classes.sectionItemSubtitle}>
                SASAYAグループ対象店共通で使えるのでバラエティ豊富！
              </div>
              <div
                className={this.props.classes.sectionItemImage}
                style={{
                  backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/Feautures2.png)',
                }} />
            </div>
            <div className={classNames([this.props.classes.sectionItem, this.props.classes.sectionItemScreenshot])}>
              <div className={this.props.classes.sectionItemTitle}>
                メニューやお店を検索
              </div>
              <div className={this.props.classes.sectionItemSubtitle}>
                マップ機能を使えば近くにあるお店との出会いも！
              </div>
              <div
                className={this.props.classes.sectionItemImage}
                style={{
                  backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/Feautures3.png)',
                }} />
            </div>
          </div>
        </Section>
        <Section
          subtitle="ご利用いただける店舗"
          color="pink">
          <div className={this.props.classes.map}>
            {this.state.viewport && (
              <ExploreMap
                viewport={this.state.viewport}
                shops={this.props.shops}
                popup={this.state.popup}
                onClickMap={this.onClickMap}
                onViewportChange={this.onViewportChange} />
            )}
            <div className={this.props.classes.mapNeighborhoodSelector}>
              <MuiButton
                variant="contained"
                style={{
                  backgroundColor: Colors.primaryPink,
                }}
                className={this.props.classes.citySelectorCta}
                onClick={this.onClickMapLocationCta}>
                <LocationPinIcon className={this.props.classes.locationPinIcon} />
                <div className={this.props.classes.cityName}>
                  {this.state.mapLocation.label}
                </div>
                <DownArrowIcon className={this.props.classes.downArrowIcon} />
              </MuiButton>
              <Collapse in={this.state.mapLocationSelectorShowing}>
                <div className={this.props.classes.cityDropdown}>
                  <List>
                    {MAP_LOCATIONS.map(mapLocation => (
                      <ListItem
                        key={mapLocation.value}
                        button
                        classes={{
                          root: this.props.classes.cityListItem,
                        }}
                        onClick={() => this.onSelectMapLocation(mapLocation)}>
                        <ListItemText
                          disableTypography
                          classes={{
                            root: this.props.classes.cityListItemText,
                          }}
                          primary={mapLocation.label} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Collapse>
            </div>
          </div>
        </Section>
        <Section
          noHorizontalPadding
          subtitle={renderDynamicCopyString(copy.recommend.subtitle)}>
          <Slider
            centerMode
            infinite
            autoplay
            swipe={false}
            arrows={false}
            pauseOnHover={false}
            dots={false}
            speed={700}
            easing="easeIn"
            autoplaySpeed={3000}
            responsive={[{
              breakpoint: BREAKPOINTS['sm'],
              settings: {
                slidesToShow: 1,
              }
            }, {
              breakpoint: BREAKPOINTS['xl'],
              settings: {
                slidesToShow: 3,
              }
            }, {
              breakpoint: BREAKPOINTS['xl'] * 2,
              settings: {
                slidesToShow: 5,
              }
            }, {
              breakpoint: 9999,
              settings: {
                slidesToShow: 7,
              }
            }]}
            className={this.props.classes.mealImages}
            customPaging={() => false}>
            {MEALS.map(meal => (
              <div
                key={meal.id}
                className={this.props.classes.mealImageContainer}>
                <Image
                  src={meal.image}
                  className={this.props.classes.mealImage}
                  renderLoader={LazyLoader} />
                <div className={this.props.classes.mealShadow} />
                <div className={this.props.classes.mealNeighborhood}>
                  <LocationPinIcon className={this.props.classes.mealLocationPin} />
                  <div className={this.props.classes.mealNeighborhoodText}>
                    {meal.neighborhood}
                  </div>
                </div>
                <div className={this.props.classes.mealInfo}>
                  <div className={this.props.classes.mealTitle}>
                    {meal.name}
                  </div>
                  <div className={this.props.classes.mealSubtitle}>
                    {meal.shop}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Section>
        <Section
          subtitle="使い方はかんたん３ステップ">
          <div className={this.props.classes.sectionItems}>
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section1.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section1.text)}
                </>
              )}
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section2.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section2.text)}
                </>
              )}
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section3.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section3.text)}
                </>
              )}
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
          </div>
        </Section>
        <Section
          title="料金プラン"
          subtitle="月額　12,980円（税込）"
          color="pink">
          <div className={this.props.classes.plan}>
            ※1日1回まで(夜中24時リセット) ※ 次の4店舗では、700円割引として利用頂けます(八百屋とごはんおおくら、うらや本店、うらや西本町店、うらや福島店) ※その他店舗では、1,000円のチケットとして利用いただけます。 ※1000円以上は差額分お支払いでご注文できます。 ※ご利用はご本人様のみ ※店内飲食限定 ※定期券ご利用でのお席のご予約はお断りさせていただいております。※喫煙可のお店が多数あり未成年様のご入店をお断りさせて頂いております。店舗一覧でご確認下さい。ランチ営業している店舗は現在曜日により16〜18店舗になります。
          </div>
        </Section>
        <Section subtitle="よくある質問">
          <div ref={ref => this.faqRef = ref} />
          {FAQ_QUESTIONS.map((item, index) => (
            <Accordion
              key={index}
              expanded={this.state.expandedPanelIndex === index}
              className={this.props.classes.faqItem}
              onChange={() => this.onChangeExpandedPanel(index)}>
              <AccordionSummary
                expandIcon={(<ExpandMoreIcon />)}
                className={this.props.classes.faqTitle}>
                {item.question}
              </AccordionSummary>
              <AccordionDetails className={this.props.classes.faqBody}>
                {item.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </Section>
        {copy.bottomButton && (
          <Button
            style={{
              zIndex: 10,
              position: 'fixed',
              bottom: 'calc(16px + env(safe-area-inset-bottom))',
              left: 'calc(50% - 150px)',
              width: 300,
              maxWidth: '90vw',
              boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
            }}
            text={'無料でアカウント作成　＞'}
            secondaryText={'今なら1食313円のお試しキャンペーン中！'}
            secondaryTextClassName={this.props.classes.scrollSecondaryText}
            onClick={this.onClickSignup} />
        )}
        <AppDownloadBar />
        <Footer />
      </div>
    )
  }
}

LandingPage.getInitialProps = async context => {
  await setCookieAndLoadProfile(context)

  redirectIfLoggedIn(context)

  const state = context.store.getState()

  if (!state?.api?.shops?.default?.loaded) {
    await context.store.dispatch(getShops())
  }

  return {
    pathname: context.pathname,
    query: context.query,
  }
}

const mapStateToProps = state => ({
  shops: state.api.shops.default,
})

export default connect(mapStateToProps)(withStyles(styles)(LandingPage))
